  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5); /* Dimmed background */
  }

  .modal-content {
    position: absolute;
    top: 10%;
    left: 10%;
    right: 10%;
    bottom: 10%;
    border: 1px solid #ccc;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensures content and buttons are spaced apart */
  }

  .content-container {
    overflow-y: auto; /* Makes this part of the modal scrollable */
    padding: 20px;
  }

  .button-container {
    display: flex;
    justify-content: space-evenly;
    padding: 20px;
    border-top: 1px solid #ccc; /* Visual separation of buttons from content */
  }
    
  /* Styling for buttons, assuming basic CSS if Tailwind is not used */
  .printbutton, .close {
    background-color: #007bff; /* Bootstrap primary color for example */
    color: white;
    border: none;
    padding: 8px 16px;
    margin: 4px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  
  /* Flex container for buttons */
  .buttons-container {
    display: flex;
    justify-content: center;
    width: 100%; /* Full width */
    padding-top: 20px; /* Space from content above */
  }
  
  /* Table styles */
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  table, th, td {
    border: 1px solid black;
  }
  
  th, td {
    padding: 8px;
    text-align: left;
  }
  
  th {
    background-color: #f2f2f2;
  }

  .letters-table {
    margin-left: auto;
    margin-right: auto;
    max-width: 90%; /* Or any other width you prefer */
  }


  .printbutton {
    color: #3384d6;
    font-size: 28px;
    font-weight: bold;
  }
  
  @media print {
    body * {
      visibility: hidden;
    }
    .printable-content, .printable-content * {
      visibility: visible;
    }
    .printable-content {
      position: absolute;
      left: 20%;
      top: 12%;
      right: 20%;
      bottom: 10%;
      width: 60%;
      margin: 0;
      box-shadow: none;
      border: none;
      page-break-after: always;
    }
    .printable-content p {
      text-align: justify;
      line-height: 1.6;
      font-size: 16px;
    }
    .printable-content .page-break {
      display: none;
    }
    .page-break {
      page-break-after: always;
    }
  }
  