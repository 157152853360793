/* Modal.css */
.modal {
    position: relative;
    z-index: 1000; /* Ensure it's above other items */
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content-wrapper {
    height: calc(100% - 100px); /* subtract the height of the header and button container */
    overflow-y: auto;
  }

  .modal-content-container {
    position: relative;
    max-height: 80vh;
    width: 50vw; /* Adjust width as needed */
    overflow-y: auto; /* This will take care of scrolling */
    background: #fff;
    padding: 20px; /* Or as much as you need */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    flex-direction: column;
    justify-content: space-between;
    display: flex
  }

  .overlay-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
  }

  .modal-content {
    background-color: #fefefe;
    margin: 15px auto;
    padding: 20px;
    border: 1px solid #888;
    width: 70%; /* Increase the width */
    max-width: 1200px; /* Increase the maximum width */
    height: 60%; /* Add a height */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: 5px;
    position: relative;
    overflow: auto;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  .date-heading {
    margin-bottom: 0px; /* Adjust the margin as needed */
  }

  .date {
    margin-top: 0px; /* Adjust the margin as needed */
  }