.overlay-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
  }
  
  .modal-content-container {
    position: relative;
    max-height: 80vh;
    width: 50vw; /* Adjust width as needed */
    overflow-y: auto; /* This will take care of scrolling */
    background: #fff;
    padding: 20px; /* Or as much as you need */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    flex-direction: column;
    justify-content: space-between;
    display: flex
  }
